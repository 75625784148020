export const form = {
	id:undefined,
	status:undefined,
	skuName:undefined,
	shopId:undefined,
	vendorId:undefined,
	brandId:undefined,
	categoryFirstId:undefined,
	categorySecondId:undefined,
	categoryThirdId:undefined,
	sellPrice:undefined,
	discountPrice:undefined,
}
export const rules = {
	skuName:[{required:true,message:'请输入',trigger:'blur'}],
	shopId:[{required:true,message:'请选择',trigger:'change'}],
	status:[{required:true,message:'请选择',trigger:'change'}],
	vendorId:[{required:true,message:'请选择',trigger:'change'}],
	brandId: [{ required: true, message: '请选择', trigger: 'change' }],
	sellPrice:[{required:true,message:'请输入',trigger:'blur'}],
	discountPrice:[{required:true,message:'请输入',trigger:'blur'}],
}
export const options = {
	cate:[],
	shop: [],
	vendor: [],
	brand: [],
}
