<template>
  <div>
    <a-drawer
      title="jcook商品上架推送"
      :width="720"
      :visible="show"
      :body-style="{ paddingBottom: '60px' }"
      @close="addClose"
    >
      <div class="drawer-content">
        基本信息
        <a-divider></a-divider>
        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          layout="vertical"
        >
          <a-row>
            <a-col :span="12">
              <a-form-model-item prop="status" label="上架状态">
                <a-switch v-model="form.status"></a-switch
              ></a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item
                prop="skuName"
                label="商品名称"
              >
                <a-input v-model="form.skuName" placeholder="请输入商品名称" style='width:60%'></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item
                prop="shopId"
                label="店铺"
              >
                <a-select v-model="form.shopId" placeholder="请选择店铺" style='width:60%'>
                  <a-select-option v-for="item in options.shop" :key="item.id" :value="item.id">{{item.name}}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item
                prop="vendorId"
                label="供应商"
              >
                <a-select v-model="form.vendorId" placeholder="请选择供应商" style='width:60%'>
                  <a-select-option v-for="item in options.vendor" :key="item.id" :value="item.id">{{item.name}}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item
                prop="brandId"
                label="品牌"
              >
                <a-select v-model="form.brandId" placeholder="请选择品牌" style='width:60%'>
                  <a-select-option v-for="item in options.brand" :key="item.id" :value="item.id">{{item.name}}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item prop="cateValue" label="商品分类">
                <a-cascader
                  v-model="cateValue"
                  :options="options.cate"
                  placeholder="请选择分类"
                  :field-names="{
                    label: 'name',
                    value: 'id',
                    children: 'shopCategoryVoList',
                  }"
                  @change="change"
                  style="width: 60%"
                ></a-cascader> </a-form-model-item
            ></a-col>
            <a-col :span="12">
              <a-form-model-item prop="sellPrice" label="售卖价">
                <a-input v-model="form.sellPrice" style="width: 60%"></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item prop="discountPrice" label="划线价">
                <a-input
                  v-model="form.discountPrice"
                  style="width: 60%"
                ></a-input> </a-form-model-item
            ></a-col>
          </a-row>
        </a-form-model>
      </div>
      <div class="drawer-footer">
        <a-button :style="{ marginRight: '8px' }" @click="addClose">
          关闭
        </a-button>
        <a-button type="primary" @click="submit"> 提交 </a-button>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import { form, rules, options } from "./form.js";
import {
	cateSelect,
	shopSelect,
	vendorSelect,
	brandSelect,
	goodsEdit,
	goodsInfo,
} from "@/api/shop/goods/index.js";
export default {
	props: {
		show: {
			type: Boolean,
			default: false,
		},
		goodsId: Number,
	},
	data() {
		return {
			form,
			rules,
			options,
			fileList: [],
			cateValue: [],
		};
	},
	mounted() {
		this.getItem();
	},
	methods: {
		getItem() {
			// 分类 店铺 供应商 品牌
			Promise.all([
				cateSelect(),
				shopSelect(),
				vendorSelect(),
				brandSelect(),
			]).then((res) => {
				this.options.cate = res[0].data;
				this.options.shop = res[1].data;
				this.options.vendor = res[2].data;
				this.options.brand = res[3].data;
			});
		},
		addClose() {
			this.$refs.ruleForm.resetFields();
			this.form.categoryFirstId = undefined;
			this.form.categorySecondId = undefined;
			this.form.categoryThirdId = undefined;
			this.cateValue = [];
			this.$emit("addClose");
		},
		success() {
			this.$emit("success");
			this.addClose();
		},
		change(data) {
			// console.log(data);
			this.form.categoryFirstId = data[0];
			this.form.categorySecondId = data[1];
			this.form.categoryThirdId = data[2];
		},
		submit() {
			this.$refs.ruleForm.validate(async (valid) => {
				if (valid) {
					let res = await goodsEdit(this.form);
					if (res.code === 200) {
						this.$message.success(res.msg);
						this.success();
					} else {
						this.$message.error(res.msg);
					}
				}
			});
		},
	},
	watch: {
		goodsId: {
			handler(val) {
				if (val !== null) {
					this.form.id = val;
					goodsInfo({ appGoodsPushId: val }).then((res) => {
						this.form = res.data;
						this.cateValue[0] = res.data.categoryFirstId
						this.cateValue[1] = res.data.categorySecondId
						this.cateValue[2] = res.data.categoryThirdId
						// console.log(this.cateValue);
					});
				}
			},
			immediate: true,
		},
	},
};
</script>

<style></style>
