import httpService from "@/request"

// 商品
// list
export function shopPushList(params) {
	return httpService({
		url: `/user/shop/shopPushList`,
		method: 'get',
		params: params,
	})
}
export function jcookGoodsList(params) {
	return httpService({
		url: `/user/shop/jcookGoodsList`,
		method: 'get',
		params: params,
	})
}
export function offShelf(params) {
	return httpService({
		url: `/user/shop/offShelf`,
		method: 'post',
		data: params,
	})
}
export function onShelf(params) {
	return httpService({
		url: `/user/shop/onShelf`,
		method: 'post',
		data: params,
	})
}
export function jcookGoodsBatchPush(params) {
	return httpService({
		url: `/user/shop/jcookGoodsBatchPush`,
		method: 'post',
		data: params,
	})
}
export function jcookGoodsPush(params) {
	return httpService({
		url: `/user/shop/jcookGoodsPush`,
		method: 'post',
		data: params,
	})
}
export function goodsEdit(params) {
	return httpService({
		url: `/user/shop/update`,
		method: 'post',
		data: params,
	})
}
export function goodsInfo(params) {
	return httpService({
		url: `/user/shop/findById`,
		method: 'get',
		params: params,
	})
}

// 分类
export function cateSelect(params) {
	return httpService({
		url: `/user/shopCategory/listAll`,
		method: 'get',
		params: params,
	})
}
// 店铺
export function shopSelect(params) {
	return httpService({
		url: `/user/shopShop/allList`,
		method: 'get',
		params: params,
	})
}
// 供应商
export function vendorSelect(params) {
	return httpService({
		url: `/user/shopVendor/allList`,
		method: 'get',
		params: params,
	})
}
// 品牌
export function brandSelect(params) {
	return httpService({
		url: `/user/shopBrand/allList`,
		method: 'get',
		params: params,
	})
}

//开启/关闭积分兑换
export function isEnableRedeem(params) {
	return httpService({
		url: `/user/pointsGoods/isEnableRedeem`,
		method: 'get',
		params: params,
	})
}

//查询所有积分商品
export function getPointList(params) {
	return httpService({
		url: `/user/pointsGoods/list`,
		method: 'get',
		params: params,
	})
}

//设置积分
export function settingPoints(params) {
	return httpService({
		url: `/user/pointsGoods/settingPoints`,
		method: 'post',
		data: params,
	})
}

//自营
//自营商品列表
export function selfGoodsList(params) {
	return httpService({
		url: `/user/shop/selfGoodsList`,
		method: 'get',
		params: params,
	})
}

//自营商品推送
export function selfGoodsPush(params) {
	return httpService({
		url: `/user/shop/selfGoodsPush`,
		method: 'post',
		data: params,
	})
}

//自营商品批量推送
export function selfGoodsMultiPush(params) {
	return httpService({
		url: `/user/shop/selfGoodsBatchPush`,
		method: 'post',
		data: params,
	})
}

//自营商品-查询所有商品类型（级联）
export function findAllGoodsCategory(params) {
	return httpService({
		url: `/user/shopCategory/findAllGoodsCategory`,
		method: 'get',
		params: params,
	})
}

//自营商品-自营商品推送
export function selfItemPush(params) {
	return httpService({
		url: `/user/shop/selfGoodsPush`,
		method: 'post',
		data: params,
	})
}