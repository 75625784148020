<template>
  <div>
    <div class="cardTitle">
      <span>商品管理</span>
    </div>
    <!-- <searchForm :formItem="formItem" @getSearch="search($event)"></searchForm> -->
	<div class="search-box">
            <a-row>
                <a-col :span="20">
                <a-space size="large">
					<a-form-model layout="inline">
						<a-form-model-item label="sku编码" >
							<a-input v-model="searchForm.skuId" placeholder="请输入" style="width: 200px" ></a-input>
						</a-form-model-item>
						<a-form-model-item label="商品名称">
							<a-input v-model="searchForm.skuName"  style="width: 200px" placeholder="请输入"></a-input>
						</a-form-model-item>
						<a-form-model-item label="分类">
							<a-cascader v-model="casVal" :options="typeData" @change="typeChange" placeholder="请选择"
								:field-names="{ label: 'name', value: 'id', children: 'shopCategoryVoList' }">
							</a-cascader>
						</a-form-model-item>
					</a-form-model>
                    <a-button type="primary" @click='getData'>查 询</a-button>
                    <a-button @click='reset'>重 置</a-button>
                </a-space>
                </a-col>
        	</a-row>
    </div>
    <a-table
      :columns="columns"
      :data-source="tableData"
      :pagination="pagination"
      :scroll="{ x: 2400 }"
      @change="handleTableChange"
	  :loading="loading"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: selectionChoosed,
      }"
      :row-key="
        (record, index) => {
          return record.id;
        }
      "
    >
	  <template slot="point" slot-scope="text,row">
		  <a-switch
                checked-children="开"
                un-checked-children="关"
                :checked="row.isPointsGoods"
				@change="changePoingGood(row)"
          ></a-switch>
	  </template>
      <span slot="action" slot-scope="text, row">
        <a-space>
          <a class="ant-dropdown-link" @click="onSell([row.id])">上架</a>
          <a class="ant-dropdown-link" @click="offSell([row.id])">下架</a>
          <a class="ant-dropdown-link" @click="edit(row.id)">修改商品信息</a>
        </a-space>
      </span>
      <span slot="mainPhoto" slot-scope="text, row">
        <img :src="row.mainPhoto" class="table-img" alt="" />
      </span>
    </a-table>
    <div class="action">
      <a-dropdown :disabled="!hasSelected">
        <a-menu slot="overlay" @click="handleMenuClick">
          <a-menu-item key="on"> 批量上架 </a-menu-item>
          <a-menu-item key="off"> 批量下架 </a-menu-item>
        </a-menu>
        <a-button> 批量操作 <a-icon type="down" /> </a-button>
      </a-dropdown>
      <span style="margin-left: 8px">
        <template v-if="hasSelected">
          {{ `已选择 ${selectedRowKeys.length} 条` }}
        </template>
      </span>
    </div>
    <addForm
      :show="drawer.show"
      @success="success"
      @addClose="addClose"
      :goodsId="goodsId"
    ></addForm>
  </div>
</template>

<script>
import { cateList } from "@/api/shop/goods/cate.js";
import { columns, pagination, formItem } from "./depend/config.js";
import { shopPushList,offShelf,onShelf, isEnableRedeem} from "@/api/shop/goods/index.js";
import addForm from "./depend/form.vue";
export default {
	components: {
		addForm,
	},
	data() {
		return {
			drawer: {
				show: false,
			},
			searchForm: {
				skuId: undefined,
				skuName: undefined,
				categoryFirstId: undefined,
				categorySecondId: undefined,
				categoryThirdId: undefined,
			},
			loading: false,
			typeData: [],
			casVal: [],
			columns,
			goodsId:null,
			pagination,
			formItem,
			tableData: [],
			selectedRowKeys: [],
		};
	},
	mounted() {
		this.getApi();
		this.getData();
	},
	methods: {
		getApi() {
			cateList().then(res => {
				let data = res.data;
				this.typeData = data;
			})
		},
		getData() {
			this.loading = true;
			shopPushList({
				...this.searchForm,
				pageNum: this.pagination.current,
				size: this.pagination.pageSize,
			}).then((res) => {
				this.tableData = res.data.rows;
				this.pagination.total = res.data.total;
				this.loading = false;
			});
		},
		reset() {
			this.searchForm = {
				skuId: undefined,
				skuName: undefined,
				categoryFirstId: undefined,
				categorySecondId: undefined,
				categoryThirdId: undefined,
			};
			this.casVal = [];
			this.getData()
		},
		selectionChoosed(data) {
			this.selectedRowKeys = data;
		},
		handleTableChange(pagination) {
			const pager = { ...this.pagination };
			pager.current = pagination.current;
			pager.pageSize = pagination.pageSize;
			this.pagination = pager;
			this.selectedRowKeys = [];
			this.getData();
		},
		edit(id) {
			this.goodsId = id;
			this.drawer.show = true;
		},
		onSell(ids) {
			this.$confirm({
				title: "是否上架",
				icon: "caret-up",
				onOk: async () => {
					let res = await onShelf({ ids: ids });
					if (res.code === 200) {
						this.$message.success(res.msg);
						this.getData();
					} else {
						this.$message.error(res.msg);
					}
				},
			});
		},
		offSell(ids) {
			this.$confirm({
				title: "是否下架",
				icon: "caret-down",
				onOk: async () => {
					let res = await offShelf({ ids: ids });
					if (res.code === 200) {
						this.$message.success(res.msg);
						this.getData();
					} else {
						this.$message.error(res.msg);
					}
				},
			});
		},
		handleMenuClick(data) {
			if (data.key === "on") {
				this.onSell(this.selectedRowKeys);
			} else if (data.key === "off") {
				this.offSell(this.selectedRowKeys);
			}
		},
		addClose() {
			this.drawer.show = false;
			this.goodsId = null;
		},
		success() {
			this.getData();
		},
		//改变积分兑换
		changePoingGood(val) {
			isEnableRedeem({appGoodsPushId: val.id}).then(res => {
				if (res.code === 200) {
					this.$message.success(res.msg);
					this.getData();
				} else {
					this.$message.error(res.msg);
				}
			})
		},
		typeChange(value) {
			this.searchForm.categoryFirstId = value[0];
			this.searchForm.categorySecondId = value[1];
			this.searchForm.categoryThirdId = value[2];
		}
	},
	computed: {
		hasSelected() {
			return this.selectedRowKeys.length > 0;
		},
	},
};
</script>

<style lang="less" scoped>
.table-img {
  width: 40px;
  height: 40px;
}
</style>
