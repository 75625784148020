export const columns = [
	{
		title: "商品状态",
		width: "6%",
		dataIndex: "status",
		customRender: function (status) {
			switch (status) {
			case false:
				return '已下架'
			case true:
				return '上架中'
			default:
				break;
			}
		}
	},
	{
		title: "商品名称",
		width: "11%",
		dataIndex: "skuName",
	},
	{
		title: "主图url",
		width: "4%",
		dataIndex: "mainPhoto",
		scopedSlots: { customRender: "mainPhoto"}
	},
	{
		title: "积分兑换",
		width: "5%",
		dataIndex: "isPointsGoods",
		scopedSlots: { customRender: "point"}
	},
	{
		title: "sku编码",
		width: "10%",
		dataIndex: "skuId",
	},
	{
		title: "商品类型",
		width: "4%",
		dataIndex: "mallType",
		customRender: function (mallType) {
			switch (mallType) {
			case 1:
				return 'Jcook'
				break;
			default:
				break;
			}
		}
	},
	{
		title: "店铺名",
		width: "7%",
		dataIndex: "shopName",
	},
	{
		title: "品牌名",
		width: "7%",
		dataIndex: "brandName",
	},
	{
		title: "供应商名",
		width: "7%",
		dataIndex: "vendorName",
	},
	{
		title: "一级分类名称",
		width: "6%",
		dataIndex: "categoryFirstName",
	},
	{
		title: "二级分类名称",
		width: "6%",
		dataIndex: "categorySecondName",
	},
	{
		title: "三级分类名称",
		width: "6%",
		dataIndex: "categoryThirdName",
	},
	{
		title: "售卖价",
		width: "4%",
		dataIndex: "sellPrice",
	},
	{
		title: "折扣价",
		width: "4%",
		dataIndex: "discountPrice",
	},
	{
		title: "浏览量",
		width: "5%",
		dataIndex: "viewsNum",
	},
	{
		title: "操作",
		dataIndex: "action",
		key: "action",
		width: "250",
		fixed: "right",
		scopedSlots: { customRender: "action" },
	},
]
export const formItem = [
	{
		type: 'input',
		label:'sku编码',
		prop:'skuId',
		placeholder:'请输入'
	},
	{
		type: 'input',
		label:'商品名称',
		prop:'skuName',
		placeholder:'请输入'
	},
	// {
	// 	type: 'select',
	// 	label:'上架状态',
	// 	prop:'status',
	// 	placeholder:'请选择',
	// 	option:[{ id: false,name:'下架'},{ id: true,name:'上架'}],
	// },
]
export const pagination = {
	current: 1,
	total: 0,
	pageSize: 100,
	showTotal: (total) => `共 ${total} 条`,
	pageSizeOptions: ['10','50','100','200'],
	showSizeChanger: true,
	showQuickJumper: true,
}