import httpService from "@/request"

// 商品
// 品牌
export function cateList(params) {
	return httpService({
		url: `/user/shopCategory/listAll`,
		method: 'get',
		params: params,
	})
}
export function cateShow(params) {
	return httpService({
		url: `/user/shopCategory/isShow`,
		method: 'get',
		params: params,
	})
}
export function cateInfo(params) {
	return httpService({
		url: `/user/shopCategory/listByParentId`,
		method: 'get',
		params: params,
	})
}
export function cateUpdate(params) {
	return httpService({
		url: `/user/shopCategory/updateCategory`,
		method: 'post',
		data: params,
	})
}
export function cateInsert(params) {
	return httpService({
		url: `/user/shopCategory/insert`,
		method: 'post',
		data: params,
	})
}
export function cateDel(params) {
	return httpService({
		url: `/user/shopCategory/delete`,
		method: 'post',
		data: params,
	})
}
